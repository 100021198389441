.about-us {
  display: flex;
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-color: white;
  @media screen and (max-width: 1199px) {
    text-align: center;
  }
}
.about-us__picture {
  position: relative;
  flex-shrink: 0;
  width: 52.4375%;
  @media screen and (max-width: 1199px) {
    display: none;
  }
}
.about-us__content {
  flex-grow: 1;
  padding-left: var(--container-indent);
  padding-right: 5.375%;
}
.about-us__picture-img {
  width: 100%;
  height: 100%;
  object-position: left;
  object-fit: cover;
}
.about-us-title {
  margin-bottom: 5rem;
  color: #036;
  font-family: $Roboto-font;
  font-size: 4.8rem;
  font-weight: 500;
  line-height: 1.17;
  text-transform: uppercase;
  @media screen and (max-width: 992px) {
    margin-bottom: 3rem;
    font-size: 4rem;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 2rem;
    font-size: 3.6rem;
  }
}
.about-us-text {
  margin-bottom: 7rem;
  color: #424242;
  font-family: $Roboto-font;
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 1.54;
  @media screen and (max-width: 992px) {
    margin-bottom: 4rem;
    font-size: 1.8rem;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 2rem;
    font-size: 1.6rem;
  }
}
.about-us-info {
  position: relative;
  margin-bottom: 6rem;
  color: #036;
  font-family: $Roboto-font;
  font-style: italic;
  font-size: 2.4rem;
  line-height: 1.54;
  @media screen and (max-width: 992px) {
    font-size: 1.8rem;
  }
}
.about-us__picture-guarantee {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  display: block;
  width: 30%;
  z-index: 1;
  @media screen and (max-width: 1199px) {
    max-width: 30rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: static;
    transform: translateY(0);
  }
  @media screen and (max-width: 767px) {
    max-width: 20rem;
  }
}
