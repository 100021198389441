.hiring {
  padding: 15rem 50vw 23rem var(--container-indent);
  background-color: #01141b;
  background-image: url('../assets/images/hiring-bg.jpg');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: cover;
  @media screen and (max-width: 1199px) {
    padding: 10rem var(--container-indent);
    text-align: center;
    background-image: none;
  }
}
.hiring-title {
  margin-bottom: 4rem;
  color: white;
  font-family: $Roboto-font;
  font-size: 9.5rem;
  font-weight: 500;
  @media screen and (max-width: 1199px) {
    font-size: 6rem;
  }
  @media screen and (max-width: 767px) {
    font-size: 3.6rem;
  }
  &[href] {
    margin-bottom: 0;
    &:hover {
      color: $primary-color;
    }
  }
}
