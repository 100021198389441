.home-hero {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  min-height: 100vh;
  @media screen and (max-width: 1439px) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      background-color: transparentize(white, 0.3);
      z-index: 1;
    }
    .def-header {
      position: relative;
      z-index: 90;
    }
  }
}
.home-hero__content {
  flex-grow: 1;
  padding-top: 10rem;
  padding-bottom: 5rem;
  @media screen and (max-width: 1439px) {
    position: relative;
    z-index: 1;
  }
}
.home-hero-container {
  max-width: 95rem;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 1199px) {
    padding-left: var(--container-indent);
    padding-right: var(--container-indent);
  }
}
.home-hero__title {
  margin-bottom: 3rem;
  color: #036;
  font-family: $Roboto-font;
  font-size: 4.2rem;
  font-weight: 400;
  line-height: 1.14;
  @media screen and (max-width: 1199px) {
    font-size: 4rem;
    br {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 3rem;
  }
}
.home-hero__text {
  margin-bottom: 3.7rem;
  color: #646464;
  font-family: $Roboto-font;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.89;
  @media screen and (max-width: 767px) {
    font-size: 1.6rem;
  }
}
