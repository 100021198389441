.def-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: var(--container-indent);
  @media screen and (max-width: 1023px) {
    padding-top: 1.5rem;
    align-items: center;
    padding-right: var(--container-indent);
  }
}
.def-header__logo {
  display: inline-block;
  padding-top: 0.5rem;
  max-width: 34rem;
  @media screen and (max-width: 1439px) {
    padding-top: 1.5rem;
    max-width: 22rem;
  }
  @media screen and (max-width: 1199px) {
    padding-top: 0;
    max-width: 15rem;
  }
}
.def-header__logo-img {
  display: block;
  width: 100%;
}
.def-header__navigation {
  @media screen and (max-width: 1023px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    padding-left: var(--container-indent);
    padding-right: var(--container-indent);
    width: 80vw;
    height: 100%;
    box-shadow: 2rem 0 5rem 0 transparentize(black, 0.6);
    overflow-y: auto;
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
    transition: transform 400ms ease, opacity 300ms ease, visibility 300ms ease;
    z-index: 100;
  }
}
.def-header__navigation--open {
  @media screen and (max-width: 1023px) {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}
.def-header__navigation-mobile-close,
.def-header__navigation-mobile-open {
  display: none;
  @media screen and (max-width: 1023px) {
    display: block;
    border: 0;
    background-color: transparent;
    outline: none;
    position: relative;
    width: 4rem;
    height: 4rem;
    padding: 0;
    margin-left: auto;
    cursor: pointer;
    &:hover,
    &:focus {
      background-color: lighten(black, 90%);
    }
  }
}
.def-header__navigation-mobile-close-line,
.def-header__navigation-mobile-open-line {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 0.2rem;
  background-color: black;
  border-radius: 0.2rem;
}
.def-header__navigation-mobile-close-line {
  &:nth-child(1) {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:nth-child(2) {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
.def-header__navigation-mobile-open-line {
  transform: translate(-50%, -50%);
  &:nth-child(1) {
    margin-top: -0.3rem;
  }
  &:nth-child(2) {
    margin-top: 0.3rem;
  }
}
.def-header__navigation-top,
.def-header__nav {
  padding-right: var(--container-indent);
  @media screen and (max-width: 1023px) {
    padding-right: 0;
  }
}
.def-header__navigation-top {
  text-align: right;
  @media screen and (max-width: 1023px) {
    order: 3;
  }
}
.def-header__phone-link {
  display: inline-block;
  padding: 1.8rem 2.8rem;
  font-family: $Roboto-font;
  line-height: normal;
  font-size: 2.1rem;
  font-weight: 700;
  color: $primary-color;
  @media screen and (max-width: 1439px) {
    padding: 1.2rem 2rem;
    font-size: 1.6rem;
  }
  @media screen and (max-width: 1023px) {
    display: block;
    text-align: center;
    padding: 1rem 1rem;
    font-size: 1.4rem;
  }
}
.def-header__phone-link--active {
  background-color: #231f20;
  border-bottom-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
  color: white;
  .accented-color {
    color: white;
  }
  @media screen and (max-width: 1439px) {
    border-bottom-left-radius: 3rem;
    border-bottom-right-radius: 3rem;
  }
  @media screen and (max-width: 1023px) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
  }
}
.def-header__phone-link-icon {
  font-size: 1.8rem;
  margin-right: 0.5rem;
}
.def-header__nav {
  font-size: 0;
  padding-left: 1rem;
  border-bottom: 0.1rem solid transparentize(black, 0.89);
  @media screen and (max-width: 1023px) {
    order: 2;
    flex-grow: 1;
    padding-left: 0;
  }
}
.def-header__nav-link {
  display: inline-block;
  position: relative;
  padding: 1rem 0.5rem;
  font-size: 1.6rem;
  line-height: normal;
  font-family: $Roboto-font;
  font-weight: 700;
  color: #646464;
  text-transform: uppercase;
  &::after {
    content: '';
    position: absolute;
    top: calc(100% - 0.1rem);
    left: 0;
    right: 0;
    height: 0.2rem;
    background-color: #395ba6;
    opacity: 0;
  }
  &:not(:last-of-type) {
    margin-right: 1rem;
    @media screen and (max-width: 1439px) {
      margin-right: 0.5rem;
    }
  }
  &:hover {
    &::after {
      opacity: 0.5;
    }
  }
  @media screen and (max-width: 1439px) {
    font-size: 1.4rem;
  }
  @media screen and (max-width: 1023px) {
    display: block;
  }
}
.def-header__nav-link--active {
  pointer-events: none;
  &::after {
    opacity: 1;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparentize(black, 0.2);
  z-index: 90;
}
