$icomoon-font-family: 'icomoon' !default;
$icomoon-font-path: '../assets/fonts' !default;

$icon-angle-right: '\e900';
$icon-map-marker: '\e901';
$icon-phone: '\e902';
$icon-fax: '\e903';
$icon-certificate: '\e904';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?5jbd1l')
      format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?5jbd1l')
      format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-angle-right {
  &:before {
    content: $icon-angle-right;
  }
}
.icon-map-marker {
  &:before {
    content: $icon-map-marker;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-fax {
  &:before {
    content: $icon-fax;
  }
}
.icon-certificate {
  &:before {
    content: $icon-certificate;
  }
}
