.def-btn {
  display: inline-block;
  margin: 0;
  padding: 0.9rem 3.4rem;
  border-radius: 3rem;
  border: 0;
  font-family: $Roboto-font;
  line-height: normal;
  font-size: 2.4rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  background-color: $primary-color;
  color: white;
  cursor: pointer;
  outline: none;
  &:hover,
  &:focus {
    background-color: lighten($primary-color, 10%);
  }
  &:active {
    background-color: darken($primary-color, 10%);
  }
  @media screen and (max-width: 1023px) {
    font-size: 1.8rem;
  }
  @media screen and (max-width: 767px) {
    font-size: 1.6rem;
  }
}
.def-btn--large {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-weight: 700;
}
.def-btn--icon {
  position: relative;
  padding-right: 7.7rem;
}
.def-btn__icon {
  position: absolute;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  top: 50%;
  right: 0.8rem;
  width: 4.3rem;
  height: 4.3rem;
  margin-top: -(4.3rem / 2);
  border-radius: 50%;
  font-size: 1em;
  overflow: hidden;
  @media screen and (max-width: 1023px) {
    width: 3.8rem;
    height: 3.8rem;
    margin-top: -(3.8rem / 2);
    font-size: 1.2em;
  }
}
.def-btn--accented {
  background-color: $accented-color;
  &:hover,
  &:focus {
    background-color: lighten($accented-color, 10%);
  }
  &:active {
    background-color: darken($accented-color, 10%);
  }
  .def-btn__icon {
    background-color: darken($accented-color, 18%);
  }
}
.def-btn--primary {
  background-color: $primary-color;
  &:hover,
  &:focus {
    background-color: lighten($primary-color, 10%);
  }
  &:active {
    background-color: darken($primary-color, 10%);
  }
  .def-btn__icon {
    background-color: darken($primary-color, 18%);
  }
}
