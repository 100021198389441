.services {
  padding-top: 10rem;
  padding-bottom: 8rem;
  background-color: #3f3c3d;
  @media screen and (max-width: 992px) {
    padding-top: 6rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 767px) {
    padding-top: 4rem;
  }
}
.services-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.3rem;
  margin-right: -1.3rem;
}
.services-col {
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  width: percentage(1 / 4);
  @media screen and (max-width: 1199px) {
    width: percentage(1 / 2);
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
.services-title {
  color: #fff;
  font-family: $Roboto-font;
  font-size: 4.8rem;
  font-weight: 500;
  line-height: 1.17;
  @media screen and (max-width: 992px) {
    font-size: 4rem;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 6rem;
    font-size: 3.6rem;
  }
}
.services-block {
  position: relative;
  padding-bottom: calc(2.6rem + 15%);
  height: 100%;
  &::before {
    content: '';
    position: absolute;
    top: -0.7rem;
    right: -0.7rem;
    width: 120%;
    height: 50%;
    background-color: $primary-color;
    clip-path: polygon(25% 0, 100% 0, 100% 53%, 30% 80%);
    z-index: 0;
    @media screen and (max-width: 767px) {
      top: -0.5rem;
      right: -0.5rem;
    }
  }
  &[href] {
    display: block;
    &:hover {
      .services-block__content {
        border-color: $primary-color;
      }
      .services-block__title {
        color: $primary-color;
      }
    }
  }
}
.services-block__content {
  position: relative;
  min-height: 100%;
  padding: 0 2rem 3rem;
  background-color: #3f3c3d;
  border: 0.1rem solid rgba(243, 242, 242, 0.24);
  text-align: center;
  z-index: 1;
  @media screen and (max-width: 767px) {
    padding: 0 1.5rem 2rem;
  }
}
.services-block__picture {
  display: block;
  margin-top: -15%;
  margin-bottom: 2.5rem;
  width: 40%;
  max-width: 13rem;
  @media screen and (max-width: 992px) {
    max-width: 10rem;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 1.5rem;
  }
}
.services-block__picture-img {
  display: block;
  width: 100%;
}
.services-block__title,
.services-block__text {
  color: white;
  font-family: $Roboto-font;
}
.services-block__title {
  position: relative;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.18;
  text-transform: uppercase;
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 100%;
    max-width: 7.4rem;
    height: 0.2rem;
    transform: translateX(-50%);
    background-color: white;
    opacity: 0.11;
  }
  @media screen and (max-width: 767px) {
    font-size: 1.8rem;
  }
}
.services-block__text {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.41;
  @media screen and (max-width: 767px) {
    font-size: 1.4rem;
  }
}
.services-block__watermark {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: transparentize(black, 0.5);
  background-image: url('../assets/images/yellow.svg');
  background-size: auto 70%;
  background-position: left top;
  background-repeat: no-repeat;
  z-index: 10;
  pointer-events: none;
}
