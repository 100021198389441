$System-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
  Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$Roboto-font: 'Roboto', sans-serif;

.accented-color {
  color: $accented-color;
}
.primary-color {
  color: $primary-color;
}
.text-center {
  text-align: center;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-bold {
  font-weight: 700;
}
