:root {
  --container-indent: 6.5vw;
  @media screen and (max-width: 1199px) {
    --container-indent: 4vw;
  }
  @media screen and (max-width: 767px) {
    --container-indent: 1.5rem;
  }
}

html {
  height: 100%;
}
body {
  min-height: 100%;
  font-family: $System-font;
  font-size: 1.6rem;
  color: black;
  background-color: white;
}
.def-container {
  padding-left: var(--container-indent);
  padding-right: var(--container-indent);
}
