.def-footer {
  position: relative;
  background-color: #231f20;
  &::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 100%;
    height: 0.7rem;
    background-color: $primary-color;
    width: 93%;
    @media screen and (max-width: 767px) {
      height: 0.5rem;
    }
  }
  @media screen and (max-width: 1439px) {
    &::before {
      width: 100%;
    }
  }
}
.def-footer__content {
  position: relative;
  padding-top: 8rem;
  padding-bottom: 10rem;
  padding-left: calc(20.75% + 5rem);
  padding-right: var(--container-indent);
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1439px) {
    padding-left: var(--container-indent);
  }
  @media screen and (max-width: 992px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: block;
  }
}
.def-footer__content-col--flex {
  display: flex;
  @media screen and (max-width: 767px) {
    display: block;
  }
}
.def-footer-picture {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  width: 20.75%;
  margin-top: -11.5rem;
  text-align: right;
  overflow: hidden;
  @media screen and (max-width: 1439px) {
    display: none;
  }
}
.def-footer-picture__img {
  width: 163.554217%;
  height: 100%;
  object-fit: cover;
  object-position: top right;
  // margin-left: calc((163.554217% - 100%) * -1);
}
.def-footer-list {
  margin-right: 4rem;
  padding-right: 8rem;
  border-right: 0.2rem solid transparentize(#4459a8, 0.8);
  @media screen and (max-width: 1199px) {
    padding-right: 4rem;
    margin-right: 2rem;
  }
  @media screen and (max-width: 992px) {
    margin-right: 10rem;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 4rem;
    border-right: 0;
  }
}
.def-footer-list__li {
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}
.def-footer-list__link {
  display: inline-block;
  color: #fff;
  font-family: $Roboto-font;
  font-size: 1.8rem;
  font-weight: 400;
  outline: none;
  white-space: nowrap;
  &:hover,
  &:focus {
    color: $primary-color;
  }
  @media screen and (max-width: 1199px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 767px) {
    white-space: normal;
  }
}
.def-footer-contact {
  position: relative;
  padding-left: 3rem;
  margin-bottom: 0.4em;
  color: #fff;
  font-family: $Roboto-font;
  font-size: 1.8rem;
  font-weight: 400;
  white-space: nowrap;
  @media screen and (max-width: 1199px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 767px) {
    white-space: normal;
  }
}
.def-footer-contact--first {
  margin-top: 3rem;
}
.def-footer-contact--large {
  font-size: 2.7rem;
  font-weight: 500;
  @media screen and (max-width: 1199px) {
    font-size: 2rem;
  }
}
.def-footer-contact__icon {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 0;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  text-align: center;
  transform: translateY(-50%);
  color: $primary-color;
}
.def-footer__copyright {
  display: block;
  padding-top: 2.5rem;
  padding-left: 2rem;
  @media screen and (max-width: 992px) {
    padding-left: 0;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.def-footer__copyright-img {
  display: block;
  width: 100%;
}
